import { jsPDF } from "jspdf";

import {
    certificateFontMaldenDetails,
    localePdfNameMappings
} from "./constants";

window.dataLayer = window.dataLayer || [];
let toolTimeout = null;

/**
 * Add fonts to pdf
 */
var callAddFont = function () {
    this.addFileToVFS(fontmap.postScriptName, fontmap.fontEncoding);
    this.addFont(fontmap.postScriptName, fontmap.fontName, fontmap.fontStyle);

    this.addFileToVFS(certificateFontMaldenDetails.postScriptName, certificateFontMaldenDetails.fontEncoding);
    this.addFont(certificateFontMaldenDetails.postScriptName, certificateFontMaldenDetails.fontName, certificateFontMaldenDetails.fontStyle);
};

jsPDF.API.events.push(['addFonts', callAddFont]);


/**
 * Position the copy URL tooltip according to the field that was copied
 */
function positionTooltip(tooltip, element) {
    let bounds = element.getBoundingClientRect();
    let xOffset = tooltip.offsetWidth/2 - bounds.width/2;

    tooltip.style.top = (bounds.top + window.scrollY - bounds.height + 1) + 'px' ;
    tooltip.style.left = (bounds.left - xOffset) + 'px';

}




document.addEventListener('DOMContentLoaded', function () {
    if(document.querySelector('.session-controls')){
        //download button
        document.querySelector('#download_session_data').addEventListener('click', function (e) {
            e.preventDefault();
            generatePDFAndDownload();
        });


        //copy urls and tooltip
        let tooltip = document.querySelector('.tooltip');

        document.querySelectorAll('.session-input-wrapper').forEach(copy => {
            copy.addEventListener('click', function(e){
                e.preventDefault();

                if(toolTimeout) {
                    clearTimeout(toolTimeout);
                    toolTimeout = null;
                }

                let input = this.querySelector('input');
                let type = this.dataset.type;

                positionTooltip(tooltip, e.target);
                tooltip.innerHTML = copiedLabel;

                navigator.clipboard.writeText(input.value).then(function(){
                    tooltip.classList.add('show');

                    toolTimeout = setTimeout(function(){
                        tooltip.classList.remove('show');
                        clearTimeout(toolTimeout);
                        toolTimeout = null;
                    }, 1500);
                });
            });
        });


    }

    /**
     * Generate certificate PDF and download it
     */
    function generatePDFAndDownload() {
        var pdf = new jsPDF('p', 'pt', 'a4');
        pdf = pdfAddText(pdf)
        pdfSave(pdf);
    }

    /**
     * Add name to certificate PDF
     *
     * @param pdf
     * @returns {*}
     */
    function pdfAddText(pdf) {
        var aligned = 'left';
        var x = 20;
        if (document.body.classList.contains("middle-east-north-africa")) {
            aligned = 'right';
            x = 580;
        }
        var title = document.querySelector('.session-title h1').textContent;
        var para = document.querySelector('.session-text p').textContent;
        var market = document.querySelector('.session-info .market').textContent;
        var sessionName = document.querySelector('.session-info .session-name').textContent;
        var sessionID = document.querySelector('.session-info .session-id').textContent;
        var preURLLabel = document.querySelector('.pre-url label').textContent;
        var postURLLabel = document.querySelector('.post-url label').textContent;

        var preURL = document.querySelector('#pre-session-url').value;
        var postURL = document.querySelector('#post-session-url').value;

        var sessionStats = document.querySelectorAll('.session-stats span');

        pdf.setFont(fontmap.fontName, fontmap.fontStyle);

        pdf.setFontSize(16);
        pdf.text(title, x, 40, { align: aligned });

        pdf.setFontSize(12);
        var splitPara = pdf.splitTextToSize(para, 540);

        if (splitPara.length == 1) {
            pdf.text(splitPara, x, 50, { align: aligned });
        } else {
            pdf.text(splitPara, x, 70, { align: aligned });
        }

        pdf.text(market, x, 140, { align: aligned });
        pdf.text(sessionName, x, 170, { align: aligned });
        pdf.text(sessionID, x, 200, { align: aligned });

        pdf.text(dashboardURLLabel + ":", x, 250, { align: aligned });
        pdf.setFontSize(10);
        pdf.text(sessionURL, x, 280, { align: aligned });

        pdf.setFontSize(12);
        pdf.text(preURLLabel, x, 320, { align: aligned });
        pdf.setFontSize(10);
        pdf.text(preURL, x, 350, { align: aligned });

        pdf.setFontSize(12);
        pdf.text(postURLLabel, x, 390, { align: aligned });
        pdf.setFontSize(10);
        pdf.text(postURL, x, 420, { align: aligned });

        pdf.setFontSize(12);

        var y = 460;
        sessionStats.forEach(function (value){
            pdf.text(value.textContent, x, y, { align: aligned });
            y+=30;
        });


        return pdf;
    }


    /**
     * Set certificate PDF name and download it
     *
     * @param pdf
     */
    function pdfSave(pdf) {
        var locale = document.querySelector('html').getAttribute('lang');
        if ((locale in localePdfNameMappings)) {
            pdf.save(localePdfNameMappings[locale]);
        } else {
            pdf.save(localePdfNameMappings['default']);
        }
    }

});
