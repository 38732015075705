document.addEventListener('DOMContentLoaded', function () {

    if(document.querySelector('.locale')){
        document.querySelector('.submit-form').addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector('form').submit();
        });

        $('.chosen').chosen().change(function(){

            select = document.querySelector('select');

            if(select.value && select.value != ''){
                document.querySelector('.submit-form').disabled = false;
            }

        });
    }

});
