import { jsPDF } from "jspdf";
import GoogleAnalyticsHelper from "./google-analytics-helper";
import {
    certificateFontMaldenDetails,
    localePdfNameMappings
} from "./constants";

window.dataLayer = window.dataLayer || [];

/**
 * Add fonts to pdf
 */
var callAddFont = function() {
    this.addFileToVFS(fontmap.postScriptName, fontmap.fontEncoding);
    this.addFont(fontmap.postScriptName, fontmap.fontName, fontmap.fontStyle);

    this.addFileToVFS(
        certificateFontMaldenDetails.postScriptName,
        certificateFontMaldenDetails.fontEncoding
    );
    this.addFont(
        certificateFontMaldenDetails.postScriptName,
        certificateFontMaldenDetails.fontName,
        certificateFontMaldenDetails.fontStyle
    );
};

jsPDF.API.events.push(["addFonts", callAddFont]);

document.addEventListener("DOMContentLoaded", function() {
    if (document.querySelector(".outro")) {
        document
            .querySelector("#download_cert")
            .addEventListener("click", function(e) {
                e.preventDefault();
                generatePDFAndDownload();
                trackDownload();
            });

        document
            .querySelector(".outro-form #name")
            .addEventListener("keyup", function() {
                if (document.querySelector(".outro-form #name").value != "") {
                    document.querySelector("#download_cert").disabled = false;
                } else {
                    document.querySelector("#download_cert").disabled =
                        "disabled";
                }
            });
    }

    /**
     * Generate certificate PDF and download it
     */
    function generatePDFAndDownload() {
        var pdf = new jsPDF("l", "pt", "a4");
        pdf = pdfSetBackground(pdf);
        pdf = pdfAddName(pdf);
        pdf = pdfSetDate(pdf);
        pdfSave(pdf);
    }

    /**
     * Add name to certificate PDF
     *
     * @param pdf
     * @returns {*}
     */
    function pdfAddName(pdf) {
        var nameField = document
            .querySelector(".outro-form")
            .querySelector('[name="name"]').value;
        var locale = document.querySelector("html").getAttribute("lang");

        pdf.setFont(fontmap.fontName, fontmap.fontStyle);

        pdf.setFontSize(28); // you can change size

        var splitName = pdf.splitTextToSize(nameField, 540);

        //If the initial iso code is ar set text to right alignment
        if (document.body.classList.contains("middle-east-north-africa")) {
            if (splitName.length == 1) {
                pdf.text(splitName, 700, 258, { align: "right" });
            } else {
                pdf.text(splitName, 700, 228, { align: "right" });
            }
        } else {
            if (splitName.length == 1) {
                pdf.text(splitName, 250, 258);
            } else {
                pdf.text(splitName, 250, 228);
            }
        }

        return pdf;
    }

    /**
     * Add background to certificate PDF
     *
     * @param pdf
     * @returns {*}
     */
    function pdfSetBackground(pdf) {
        var background = document
            .querySelector(".outro-form")
            .querySelector('[name="_cert"]').value;
        pdf.addImage(background, "JPG", 0, 0, 792, 598);
        return pdf;
    }

    /**
     * Set date on certificate PDF
     *
     * @param pdf
     * @returns {*}
     */
    function pdfSetDate(pdf) {
        var today = new Date();
        var locale = document.querySelector("html").getAttribute("lang");
        var options = { year: "numeric", month: "numeric", day: "numeric" };
        var dateVal = today.toLocaleString(locale, options);
        var x = 690, y = 65;
        if (document.body.classList.contains("middle-east-north-africa")) {
            dateVal = today.toLocaleString('en-GB', options);
            var x = 220;
            y = 72;
        }
        if(document.body.classList.contains("india-bengali")  || document.body.classList.contains("india-marathi")){
            dateVal = today.toLocaleString('en-GB', options);
        }
        pdf.setFont(
            certificateFontMaldenDetails.fontName,
            certificateFontMaldenDetails.fontStyle
        ); // set custom font
        pdf.setFontSize(9); // you can change size
        pdf.setTextColor(76, 76, 76);


        pdf.text(dateVal,x, y);

        return pdf;
    }

    /**
     * Set certificate PDF name and download it
     *
     * @param pdf
     */
    function pdfSave(pdf) {
        var locale = document.querySelector("html").getAttribute("lang");
        if (locale in localePdfNameMappings) {
            pdf.save(localePdfNameMappings[locale]);
        } else {
            pdf.save(localePdfNameMappings["default"]);
        }
    }

    /**
     * Track certificate PDF download
     */
    function trackDownload() {
        let locale = document
            .querySelector("html")
            .getAttribute("lang")
            .split("-")[1];

        new GoogleAnalyticsHelper().track({
            event: "customEvent",
            eventCategory: "certificate_" + locale,
            eventAction: "Certificate Download",
            eventLabel: "Certificate Download",
            eventInteraction: true,
            dimension8: locale,
            locale: locale,
            tracking_type: "certificate_download",
            token: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content")
        });
    }
});
