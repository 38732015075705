// Initialise Swiper carousel

const GoogleAnalyticsHelper = require("./google-analytics-helper");
const carouselAdvice = document.querySelector('.swiper-container-advice');

if (carouselAdvice) {
    let adviceCarousel = new Swiper('.swiper-container-advice', {
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    '<span class="' + totalClass + '"></span>';
            },
            formatFractionCurrent: function (number) {
                return String(number).padStart(2, '0');
            },
            formatFractionTotal: function (number) {
                return String(number).padStart(2, '0');
            }
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
            draggable: true,
        },
    });
}

document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('.advice')) {
        let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

        new GoogleAnalyticsHelper().track(
            {
                event: 'customEvent',
                eventCategory: 'module-completion_' + locale,
                eventAction: 'Module Complete',
                eventLabel: 'Module Complete',
                eventInteraction: true,
                dimension8: locale,
                locale: locale,
                tracking_type: 'module_completion',
                token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        );
    }
});
