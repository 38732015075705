const GoogleAnalyticsHelper = require("./google-analytics-helper");

document.addEventListener('DOMContentLoaded', function () {

    if(document.querySelector('.start')){
        document.querySelector('.submit-form').addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector('.submit-form').disabled = true;

            let select = document.querySelectorAll('select');
            let age = select[0].value;
            let gender = select[1].value;
            let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

            new GoogleAnalyticsHelper().track(
                {
                    event: 'customEvent',
                    eventCategory: 'intro-survey_' + locale,
                    eventAction: 'Age',
                    eventLabel: age,
                    eventInteraction: true,
                    dimension8: locale,
                    locale: locale,
                    tracking_type: 'intro-survey',
                    token: $('meta[name="csrf-token"]').attr('content')
                }
            );

            new GoogleAnalyticsHelper().track(
                {
                    event: 'customEvent',
                    eventCategory: 'intro-survey_' + locale,
                    eventAction: 'Gender',
                    eventLabel: gender,
                    eventInteraction: true,
                    dimension8: locale,
                    locale: locale,
                    tracking_type: 'intro-survey',
                    token: $('meta[name="csrf-token"]').attr('content')
                }
            );

            document.querySelector('form').submit();
        });

        $('.chosen').chosen().change(function(){
            selectElements = document.querySelectorAll('select');
            let total = selectElements.length;
            let complete = [];

            selectElements.forEach(element => {
                if(element.value && element.value != ''){
                    complete.push(element);
                }
            });

            if(total == complete.length){
                document.querySelector('.submit-form').disabled = false;
            }
        });
    }

});
