const GoogleAnalyticsHelper = require("./google-analytics-helper");
var currentIndex = 0;
var percent = 0;
window.dataLayer = window.dataLayer || [];


function nextQuestion(){
    submitAnswer(currentIndex);
    //increase the question index to the next question's index
    currentIndex++;
    if(document.querySelectorAll('.q-answers-'+currentIndex+' input:checked').length == 0){
        document.querySelector('.q-button-goto-stories').disabled = true;
        document.querySelector('.q-button-next-question').disabled = true;
    }
    // TODO add ajax to submit once we know where/how it's being stored
    // clear the current active question & show the new active question
    toggleIsActive('.q-question, .q-answers', '.q-question-'+currentIndex+', .q-answers-'+currentIndex)
    //if it is the last page
    // change the next question button to go to stories
    if(currentIndex == maxIndex){ toggleIsActive('.q-button-next-question', '.q-button-goto-stories'); }
    // If we have just come from the first question
    // remove the link that takes you back a page and sshow the prev question button
    if(currentIndex == 1){ toggleIsActive('.q-button-go-back', '.q-button-prev-question'); }
    //update the progress number/bar
    updateQuestionProgress();
}

function prevQuestion(e){
    e.preventDefault();
    //reduce the question index to the previous question's index
    currentIndex--;
    if(document.querySelectorAll('.q-answers-'+currentIndex+' input:checked').length > 0){
        document.querySelector('.q-button-goto-stories').disabled = false;
        document.querySelector('.q-button-next-question').disabled = false;
    }
    // clear the current active question & show the new active question
    toggleIsActive('.q-question, .q-answers', '.q-question-'+currentIndex+', .q-answers-'+currentIndex);
    // if we have just come from the last question
    //show the next question button & hide the "go to stories" button
    if(currentIndex == (maxIndex - 1)){ toggleIsActive('.q-button-goto-stories', '.q-button-next-question'); }
    // if it's the first question
    // remove the prev question button and show the link to return to the prev page (both look visually the same)
    if(currentIndex == (0)){ toggleIsActive('.q-button-prev-question', '.q-button-go-back'); }
    //update the progress number/bar
    updateQuestionProgress();
}

function updateQuestionProgress(){
    percent = (currentIndex + 1) / (maxIndex + 1) * 100;
    document.querySelector('.progress-meter-current').innerHTML = String(currentIndex+1).padStart(2, '0');
    document.querySelector('.progress-meter-range').style.cssText = '--progress: '+percent + '%;';
}

function toggleIsActive(remove, add){
    document.querySelectorAll(remove).forEach(r => {
        r.classList.remove('is-active');
    });

    document.querySelectorAll(add).forEach(a => {
        a.classList.add('is-active');
    });
}

function completePreQuestions(e){
    e.preventDefault();
    document.querySelector('.q-button-goto-stories').disabled = true;
    submitAnswer(currentIndex);
    let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

    new GoogleAnalyticsHelper().track(
        {
            event: 'customEvent',
            eventCategory: type + '-survey_' + locale,
            eventAction: 'Complete',
            eventLabel: 'Complete',
            eventInteraction: true,
            dimension8: locale,
            locale: locale,
            tracking_type: type + '-survey_' + locale,
            token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    );

    if (type === "Post") {
        new GoogleAnalyticsHelper().track(
            {
                event: 'customEvent',
                eventCategory: 'evaluation-complete_' + document.querySelector('#module-id').value + '_' + locale,
                eventAction: 'Evaluation Complete',
                eventLabel: 'Evaluation Complete',
                eventInteraction: true,
                dimension8: locale,
                locale: locale,
                tracking_type: 'evaluation_complete',
                token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        );
    }

    document.querySelector('form').submit();
}

function submitAnswer(index){
    let answer = document.querySelector('.q-answers-'+index+' input:checked').dataset.value;
    let question = document.querySelector('h1.q-question-'+index+' ').innerHTML;
    let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

    new GoogleAnalyticsHelper().track(
        {
            event: 'customEvent',
            eventCategory: type + '-survey_' + locale,
            eventAction: question,
            eventLabel: answer,
            eventInteraction: true,
            dimension8: locale,
            locale: locale,
            tracking_type: type + '-survey_' + locale,
            token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    );
}

document.addEventListener('DOMContentLoaded', function () {

    if(document.querySelector('.questions')){
        document.querySelectorAll('.radio-with-label label').forEach(radio => {
            radio.addEventListener('click', function() {
                document.querySelector('.q-button-goto-stories').disabled = false;
                document.querySelector('.q-button-next-question').disabled = false;
            });
        })

        document.querySelector('.q-button-next-question').addEventListener("click",nextQuestion);
        document.querySelector('.q-button-prev-question').addEventListener("click",prevQuestion);
        document.querySelector('.q-button-goto-stories').addEventListener("click",completePreQuestions);
        updateQuestionProgress();

        if(document.querySelector('.questions') && type == 'Post'){
            let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

            new GoogleAnalyticsHelper().track(
                {
                    event: 'customEvent',
                    eventCategory: 'module-completion_' + locale,
                    eventAction: 'Module Complete',
                    eventLabel: 'Module Complete',
                    eventInteraction: true,
                    dimension8: locale,
                    locale: locale,
                    tracking_type: 'module_completion',
                    token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
        );
        }

    }
});


