// Scripts for the Stories page


// Accordion

let accordionStart = function(ac) {
    ac.querySelectorAll('.accordion-segment').forEach(segment => {
        segment.addEventListener('click', function() {
            accordionChange(ac, this);
        });
    });
};

let accordionChange = function(ac, sg) {
	const sgActive = ac.querySelector('.is-active');
	if (sg == sgActive) return;

	sgActive.classList.remove('is-active');

	sg.classList.add('is-transitioning');
	sg.classList.add('is-active');

	setTimeout(function() {
		sg.classList.remove('is-transitioning');
	}, 500);
};


document.querySelectorAll('.accordion').forEach(accordion => {
    accordionStart(accordion);
});

// Initialise Swiper carousel
const carouselStories = document.querySelector('.swiper-container-stories');
if (carouselStories) {
	new Swiper('.swiper-container-stories', {
		slidesPerView: 'auto',
		scrollbar: {
			el: '.swiper-scrollbar',
			hide: false,
			draggable: true,
		},
	});
}

// Show/hide story card content

let initStoryCards = function() {
    if(document.querySelector('.stories')){
        document.querySelectorAll('.story-card.swiper-slide.is-collapsible').forEach(card => {
            const cardBio = card.querySelectorAll('.story-card-bio, .story-card-title, .story-card-blurb, .story-card-toggle');
            cardBio.forEach(bio => {

                bio.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    var content = this.parentNode;

                    content.classList.toggle('is-collapsed');

                    var button = content.querySelector('.story-card-toggle');

                    var text = '';
                    if(content.classList.contains('is-collapsed')){
                        text = button.dataset.more;
                    } else {
                        text = button.dataset.less;
                    }
                    button.innerHTML = text;
                });
            });

        });

        document.querySelectorAll('.story-card.story-card-featured.is-collapsible').forEach(card => {
            const cardBio = card.querySelectorAll('.story-card-bio, .story-card-title, .story-card-blurb, .story-card-toggle');
            cardBio.forEach(bio => {

                bio.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    var content = this.parentNode;

                    content.classList.toggle('is-collapsed');

                    var button = content.querySelector('.story-card-toggle');

                    var text = '';
                    if(content.classList.contains('is-collapsed')){
                        text = button.dataset.more;
                    } else {
                        text = button.dataset.less;
                    }
                    button.innerHTML = text;
                });
            });
        });
    }
}();
