document.addEventListener('DOMContentLoaded', function () {
    if(!document.querySelector('.story') && document.querySelector('.aboutModal')){
        document.querySelector('.aboutModal').addEventListener('click', function(e){
            document.querySelector('.about-content').classList.remove('is-hidden');
            document.querySelector('body').style.overflow = "hidden";
            e.preventDefault();
        });

        document.querySelector('.icon-link-about-close').addEventListener('click', function(e){
            document.querySelector('body').style.overflow = 'scroll';
            document.querySelector('.about-content').classList.add('is-hidden');
            e.preventDefault();
        });
    }
});
