document.addEventListener('DOMContentLoaded', function () {
    if(document.querySelector('.name')){


        let nameInput = document.querySelector('.facilitator-name');
        let errorText = ('facilitator_name_error_length' in language) ? language['facilitator_name_error_length'] : 'Name must contain at least 5 characters'
        errorText = `<p>${errorText}</p>`;

        let errorElement = document.querySelector('.error');

        nameInput.addEventListener('input', function(e) {
            if(nameInput.value.length > 4) {
                document.querySelector('.error').innerHTML = "";
                document.querySelector('.submit-form').disabled = false;
            } else {
                document.querySelector('.submit-form').disabled = true;
                if(!errorElement.querySelector('p')){
                    var p = document.createElement('p').innerHTML = errorText;
                    document.querySelector('.error').insertAdjacentHTML('beforeend', p);
                }

            }
        });
    }
});
