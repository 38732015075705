/**
 * FacebookPixelHelper Constructor
 *
 * clickSelector is set to '[data-fb-pixel]', the value of which will be sent to Facebook Pixel
 *
 * @constructor
 */
function FacebookPixelHelper() {
    this.clickSelector = '[data-fb-pixel]';
}

/**
 * Initialise the FacebookPixelHelper
 */
FacebookPixelHelper.prototype.init = function() {
    this.bindClickEvents();
};

/**
 * Bind click events
 */
FacebookPixelHelper.prototype.bindClickEvents = function() {
    var that = this;
    document.querySelector(this.clickSelector).addEventListener('click', function(e) {
        var clicked = this;
        that.track({
            trackingType: 'trackCustom',
            eventType: 'Click',
            parameters: {
                url: window.location.href,
                clicked: clicked.dataset.fb-pixel
            }
        });
    });
};

/**
 * Call facebook pixel helper with provided options
 *
 * @param options
 */
FacebookPixelHelper.prototype.track = function(options) {
    if (window.fbq) {
        fbq(
            options.trackingType,
            options.eventType,
            options.parameters
        );
    }
};

if(document.querySelector(this.clickSelector)){
    new FacebookPixelHelper().init();
}

module.exports = FacebookPixelHelper;
