document.addEventListener('DOMContentLoaded', function () {
	function Lineator()
    {
	    this.selector = '.lineate';
	    this.elements = document.querySelectorAll(this.selector);
    }

    Lineator.prototype.init = function()
    {
        this.lineate();
        this.bindWindowResizeEvents();
    };

	Lineator.prototype.lineate = function()
    {
        this.elements.forEach(element => {
            var lineCount = element.dataset.line-count;
            var words = element.textContent.split(' ');
            var maxWordsPerLine = Math.ceil(words.length / lineCount);
            var lines = '';

            while (words.length > 0) {
                var wordsForLine = words.splice(0, maxWordsPerLine);
                var line = wordsForLine.join(' ') + '<br/>';
                lines += line;
            }

            element.parentElement.insertAdjacentHTML('beforeend','<p class="lineator-placeholder" style="position: unset; display: inline-block;">' + lines + '</p>');

            var placeholder = document.querySelector('.lineator-placeholder');
            var newWidth = placeholder.offsetWidth;
            placeholder.parentElement.removeChild(placeholder);

            element.style.width = newWidth;
        });
    };

    Lineator.prototype.bindWindowResizeEvents = function()
    {
        var that = this;
        window.addEventListener('resize', function() {
            that.lineate();
        });
    };

	new Lineator().init();
});
