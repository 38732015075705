const GoogleAnalyticsHelper = require("./google-analytics-helper");
var myPlayer, selected, video, playerOptions, subtitles;
var players = {
    '1': {
        player: null,
        loaded:false
    },
    '2': {
        player: null,
        loaded:false
    },
    '3': {
        player: null,
        loaded:false
    },
};

function playVideo(player) {
    player.play();

    if (subtitles) {
        player.el_.querySelectorAll('.vjs-subs-caps-button .vjs-menu-item .vjs-menu-item-text').forEach(item => {
            if (item.innerHTML == subtitles) {
                item.parentNode.click();
            }
        });
    }

    let mqList = window.matchMedia('(max-width: 768px)');

    //prep the next interstitial
    var rand = Math.floor(Math.random() * videos.length);
        // load a random interstitial video into the player
    var src = videos[rand]['url'];
    if (mqList.matches && videos[rand]['vertical_url']) {
        src = videos[rand]['vertical_url'];
    }

    myPlayer.src();
    myPlayer.load();

    //when the video has ended

    player.any('ended', function() {
        players[1].loaded = false;
        players[2].loaded = false;
        players[3].loaded = false;

        removeTracks(player);
        removeTracks(players[1].player);
        removeTracks(players[2].player);
        removeTracks(players[3].player);

        document.querySelector(".storypoint-container").classList.add('is-active');

        if(document.querySelector('track')) document.querySelector('track').remove();
        //play the interstitial video
        myPlayer.play();

        // get the selected options
        options = video['options'];

        if (options.length > 0) {
            //display the interstitial video
            document.querySelector('.interstitial-box').classList.add('is-active');
            // hide the main video
            document.querySelector(".video-js-box[data-id='" + selected + "']").classList.remove('is-active');

            document.querySelector(".storypoints-loader").classList.add('is-active');
            // foreach option, set up the player for their videos
            // show the storypoints


            if (options[0] && options[0] != "undefined") {
                loadVideo(0, 1, mqList);
            } else {
                document.querySelectorAll('.video-js-box')[1].dataset.id = 0;
                players[1].loaded = true;
            }
            if (options[1] && options[1] != "undefined") {
                loadVideo(1, 2, mqList);
            } else {
                document.querySelectorAll('.video-js-box')[2].dataset.id = 0;
                players[2].loaded = true;
            }
            if (options[2] && options[2] != "undefined") {
                loadVideo(2, 3, mqList);
            } else {
                document.querySelectorAll('.video-js-box')[3].dataset.id = 0;
                players[3].loaded = true;
            }
            loadedVideo = setInterval(function() {
                if (players[1].loaded && players[2].loaded && players[3].loaded) {

                    clearInterval(loadedVideo);
                    document.querySelector(".storypoints-loader").classList.remove('is-active');

                    document.querySelector(".storypoints-" + selected).classList.add('is-active');
                    initSubtitleListeners();

                    if (options[0] && options[0] != "undefined") {
                        players[1].player.load();
                    } else if (options[1] && options[1] != "undefined") {
                        players[2].player.load();
                    } else if (options[2] && options[2] != "undefined") {
                        players[3].player.load();
                    }

                }
            }, 200);

        } else {
            fireEvent("complete");
            // no options, show the continue button
            window.location.href = document.querySelector(".storypoint-option.continue").getAttribute('href');
        }
    });
}

function removeTracks(player) {
    var tracks = player.remoteTextTrackEls();
    var i;
    var length = tracks.length;
    for (i = 0; i < length; i++) {
        player.removeRemoteTextTrack(tracks[i]);
    }
}

function loadVideo(option, player, mqList) {
    var opt = storypoints[options[option]['id']];
    var src = mqList.matches && opt['video']['vertical_url'] ? opt['video']['vertical_url'] : opt['video']['url'];

    document.querySelectorAll('.video-js-box')[player].dataset.id = options[option]['id'];

    players[player].player.src(src);

    getSubtitles('/subtitles/' + opt['video']['id'], player);
}

function getSubtitles(url, player){
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function() {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200){
            var sub = JSON.parse(xmlHttp.responseText);
            for (var i = 0; i < sub.length; i++) {
                players[player].player.addRemoteTextTrack({ src: sub[i]['subtitle_file'], label: sub[i]['language'], srclang: sub[i]['iso_locale'], default: players[player].player.hasClass("vjs-subs-on") }, false);
            }

            players[player].player.load();
            players[player].player.any('loadedmetadata', function() {
                players[player].loaded = true;
            });
        }
    }
    xmlHttp.open("GET", url, true); // true for asynchronous
    xmlHttp.send(null);
}

function initSubtitleListeners(){
    document.querySelectorAll('.vjs-subs-caps-button .vjs-menu-item').forEach(element => {
        element.addEventListener('click', function() {
            if(!element.classList.contains('vjs-texttrack-settings')){
                subtitles = element.querySelector('.vjs-menu-item-text').innerHTML;
            }
        });

        element.addEventListener('touchstart', function() {
            if(!element.classList.contains('vjs-texttrack-settings')){
                subtitles = element.querySelector('.vjs-menu-item-text').innerHTML;
            }
        });
    })
}

function playOption() {
    // hide options
    document.querySelectorAll('.storypoints').forEach(sp => {
        sp.classList.remove('is-active');
    });

    document.querySelector(".storypoint-container").classList.remove('is-active');
    selected = this.dataset.storypoint;
    //get the selected video
    video = storypoints[selected];

    //find the correct player container & display it
    var videoplayer = document.querySelector(".video-js-box[data-id='" + selected + "']");
    videoplayer.classList.add("is-active");

    // play tge correct video
    var videoid = videoplayer.querySelector('video').getAttribute("id");

    if (videoid == 'video-1_html5_api') {
        playVideo(players[1].player);
    } else if (videoid == 'video-2_html5_api') {
        playVideo(players[2].player);
    } else if (videoid == 'video-3_html5_api') {
        playVideo(players[3].player);
    }

    document.querySelector('.interstitial-box').classList.remove('is-active');
    myPlayer.pause();
}

function fireEvent(action) {
    var videono = modulesCompleted.length + 1;
    let locale = document.querySelector('html').getAttribute('lang').split('-')[1];

    new GoogleAnalyticsHelper().track(
        {
            event: 'customEvent',
            eventCategory: 'Interactive conversation_' + locale,
            eventAction: 'Video ' + videono + '_' + action,
            eventLabel: moduleTitle,
            eventInteraction: true,
            dimension8: locale,
            locale: locale,
            tracking_type: 'interactive_conversation',
            token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    );
}

document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('.story') && document.querySelector('.storypoint-option')) {
        playerOptions = { fluid: false };
        myPlayer = videojs('interstitial-video', playerOptions);
        players[1].player = videojs('video-1', playerOptions);
        players[2].player = videojs('video-2', playerOptions);
        players[3].player = videojs('video-3', playerOptions);

        document.querySelectorAll('.storypoint-option:not(.continue)').forEach(button => {
            button.addEventListener('click', playOption);
        });

        fireEvent("open");

        document.querySelector('.storypoints-start button').addEventListener("click", function() {

            fireEvent("play");

            if(document.querySelector('.video-bg')){
                document.querySelector('.video-bg').style.display = "none";
            }

            document.querySelector('.story-content').classList.add('no-after');

            // Get all text tracks for the current player.
            var tracks = players[1].player.textTracks();

            // if there is more than one track
            if (tracks.length >= 1 && players[1].player.hasClass("vjs-subs-on")) {
                 // get the first one
                var track = tracks[0];
                //  // change it's mode to 'showing'
                 track.mode = 'showing';
            }

        });

        document.querySelector(".storypoint-option.continue").addEventListener("click", function(e) {
            e.preventDefault();
            console.log("here");
            fireEvent("complete");
            console.log(document.querySelector(".storypoint-option.continue").getAttribute('href'));
            window.location.href = document.querySelector(".storypoint-option.continue").getAttribute('href');
        });

        players[1].player.any('loadedmetadata', function() {
            initSubtitleListeners();
        });
    }
});
