// Scripts for the Stories page
// Accordion
let accordionStart = function(ac) {
    ac.querySelectorAll('.accordion-segment').forEach(segment => {
        segment.addEventListener('click', function() {
            accordionChange(ac, this);
        });
    });
};

let accordionChange = function(ac, sg) {
    const sgActive = ac.querySelector('.is-active');
    if (sg == sgActive) return;

    sgActive.classList.remove('is-active');

    sg.classList.add('is-transitioning');
    sg.classList.add('is-active');

    setTimeout(function() {
        sg.classList.remove('is-transitioning');
    }, 500);
};


document.querySelectorAll('.accordion').forEach(accordion => {
    accordionStart(accordion);
});

// Show/hide story card content

let initStoriesCards = function() {
    if(document.querySelector('.support')){
        document.querySelectorAll('.story-card.is-collapsible').forEach(card => {
            const cardContent = card.querySelector('.story-card-content');
            cardContent.classList.add('is-collapsed');

            cardContent.addEventListener('click', function() {
                this.classList.toggle('is-collapsed');
            });
        });
    }
}();
